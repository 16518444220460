// @flow
import * as React from 'react'
import { A } from 'glamorous'

import { brandBlue } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'

const FAQLink = ({ children, ...other }) => (
	<A
		color={brandBlue}
		fontWeight="700"
		target="_blank"
		onClick={e => e.stopPropagation()}
		{...other}
	>
		{children}
	</A>
)

// To be used with new request a demo form when the time comes

// const getReferrerUrlString = () => {
// 	if (typeof window !== 'undefined') {
// 		return `referrer_url=${window.location.href}`
// 	}
// 	return ''
// }

export default [
	{
		question: `Is subcontractor financial and safety data made public to other TradeTapp users?`,
		answer: `Never. TradeTapp will never make qualification related data public to anyone. Everything in your account is private to you and your company.`,
	},
	{
		question: `What types of pricing and plans are available?`,
		answer: (
			<div>
				Pricing is based on how many subcontractors you qualify annually.
				TradeTapp offers pricing plans for GCs of all shapes and sizes. To get
				more pricing information,{' '}
				<FAQLink
					// href={`https://www.buildingconnected.com/request-a-demo?${getReferrerUrlString()}`}
					onClick={e => {
						e.stopPropagation()
						showRequestDemoModal()
					}}
				>
					contact sales here.
				</FAQLink>
			</div>
		),
	},
	{
		question: `How long does it take to get started?`,
		answer: (
			<div>
				We’ll get your team up and running in about 90 days. Your account rep
				will set up a team training to get you started, and they’ll always be
				available for training and questions. Check out our help center for
				additional resources:{' '}
				<FAQLink href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">
					support
				</FAQLink>
				.
			</div>
		),
	},
]
