import * as React from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	ContentWrapper,
	SectionBreak,
	Footer,
} from 'views/components'
import Header from './header'
import FAQs from './faq'
import ROICalculator from 'views/components/roi-calc'
import { defaultCalcState, ranges, inputs } from './roi-calc-data'
import ResultsSection from './roi-calc-data/results-section'
import { laptop, tablet } from 'shared/media-queries'
import { grayBackground } from 'shared/colors'
import ResourceHub from './resource-hub'
import useScreenWidth from 'hooks/use-screen-width'
import styled from 'styled-components'
import QuoteSection from './quote'
import useScrollTo from 'hooks/use-scroll-to'

const Br = styled.br`
	display: none;
	${laptop} {
		display: block;
	}
	${tablet} {
		display: none;
	}
`

const TradeTappRoi = () => {
	const roiRef = React.useRef(null)

	useScrollTo({
		keysToRefs: {
			roi: roiRef,
		},
		offset: -130,
	})

	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Qualification ROI Calculator | BuildingConnected"
			description="See how much time and money you'll save during subcontractor qualification with TradeTapp."
		>
			<Header screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<ROICalculator
					defaultState={defaultCalcState}
					ranges={ranges}
					forwardedRef={roiRef}
					inputs={inputs}
					resultsSection={state => <ResultsSection values={state} />}
					title={
						<>
							Complete the ROI Calculator to see how much TradeTapp can help you
							save.
						</>
					}
				/>
				<SectionBreak />
				<QuoteSection />
				<SectionBreak />
				<FAQs />
				<SectionBreak />
				<ResourceHub />
				<SectionSpacer />
			</ContentWrapper>

			{/* <Footer
				wording="Try Bid Board Pro with your team for free."
				subtext="Invest in the preconstruction tools that will increase productivity—and your bottom line."
				demoButtonWording="Get a demo and a quote"
				demoButtonPadding="90px"
				showCreateAccount
			/> */}
		</Layout>
	)
}

export default TradeTappRoi
