import * as React from 'react'
import styled from 'styled-components'
import {
	Wording,
	WordingBreak,
} from 'views/components/roi-calc/input-item/shared'
import { TooltipHeader, SmallSpacer, TooltipContent } from './components'
import { grayText } from 'shared/colors'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'

const Span = styled.span`
	position: relative;
	top: 2px;
	left: 4px;
`
export const keys: { [key: string]: string } = {
	SUBS: 'subcontractors',
	HOURS_SPENT: 'hoursSpent',
	ESTIMATORS: 'riskManagers',
}

export const ranges = {
	[keys.HOURS_SPENT]: { min: 1, max: 100 },
	[keys.SUBS]: { min: 0.5, max: 20000 },
	[keys.ESTIMATORS]: { min: 1, max: 20 },
}

export const defaultCalcState = {
	[keys.SUBS]: 5,
	[keys.HOURS_SPENT]: 6,
	[keys.ESTIMATORS]: 3,
}

export const inputs = [
	{
		key: keys.HOURS_SPENT,
		wording: (
			<Wording>
				Average number of hours spent <WordingBreak /> qualifying one
				subcontractor
				<Span>
					<QuestionMarkTooltip
						title={
							<div>
								<TooltipHeader>
									Ensure you include the time spent:{' '}
								</TooltipHeader>
								<SmallSpacer />
								<TooltipContent>
									Inviting subs
									<br />
									Storing/distributing data that a sub submits
									<br />
									Analyzing data
									<br />
									Assigning/approving qualification status
									<br />
									Assigning risk mitigation plans
								</TooltipContent>
							</div>
						}
						wide
						fillColor={grayText}
					/>
				</Span>
			</Wording>
		),
		min: 0.5,
		max: 100,
		step: 0.5,
		default: 5,
	},
	{
		key: keys.ESTIMATORS,
		wording: (
			<Wording>
				Average number of risk managers <WordingBreak /> that work on a
				qualification
			</Wording>
		),
		min: 1,
		max: 20,
		step: 1,
		default: 3,
	},
	{
		key: keys.SUBS,
		wording: (
			<Wording>
				Average number of subcontractor <WordingBreak /> qualifications in one
				year
			</Wording>
		),
		min: 1,
		max: 20000,
		step: 1,
		default: 6,
	},
]
