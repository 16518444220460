// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { cyan } from 'shared/colors'
import { H2, FAQTable } from 'views/components'
import faqs from './faqs'

const FAQsSection = () => (
	<Div>
		<H2 textAlign="center">FAQs</H2>
		<Div height="40px" />
		<FAQTable faqs={faqs} borderColor={cyan} numbered />
	</Div>
)

export default FAQsSection
