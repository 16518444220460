// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { ResourceHub } from 'views/components'
import firstBlogImage from './img/tt-blog-1.png'
import firstBlogImage2x from './img/tt-blog-1@2x.png'
import firstBlogImage3x from './img/tt-blog-1@3x.png'

import secondBlogImage from './img/tt-blog-2.png'
import secondBlogImage2x from './img/tt-blog-2@2x.png'
import secondBlogImage3x from './img/tt-blog-2@3x.png'

import thirdBlogImage from './img/tt-blog-3.png'
import thirdBlogImage2x from './img/tt-blog-3@2x.png'
import thirdBlogImage3x from './img/tt-blog-3@3x.png'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={firstBlogImage}
				srcSet={`${firstBlogImage2x} 2x, ${firstBlogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: 'Key Financial & Safety Metrics for Qualification',
		cta: 'Read more',
		href: 'https://constructionblog.autodesk.com/subcontractor-qualification/',
	},
	{
		image: (
			<Img
				width="100%"
				src={secondBlogImage}
				srcSet={`${secondBlogImage2x} 2x, ${secondBlogImage3x} 3x`}
			/>
		),
		title: 'Report',
		wording: '2021 Autodesk Construction Outlook',
		cta: 'Read more',
		href: `https://construction.autodesk.com/resources/2021-autodesk-construction-outlook/`,
	},
	{
		image: (
			<Img
				width="100%"
				src={thirdBlogImage}
				srcSet={`${thirdBlogImage2x} 2x, ${thirdBlogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: 'Why Bid Management and Qualification Drive Success',
		cta: 'Read more',
		href: `https://constructionblog.autodesk.com/bid-management-qualification-workflows/`,
	},
]

const ResourceHubSection = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Learn more about TradeTapp’s risk management capabilities."
		cards={cards}
	/>
)

export default ResourceHubSection
