import * as React from 'react'
import { useState } from 'react'
import {
	A,
	RootContainer,
	ResultsItemContainer,
	SmallWidthSpacer,
	ResultsSpan,
	TooltipHeader,
	SmallSpacer,
	TooltipContent,
	ResultContent,
	VariableSpacer,
	ResultsSpacer,
	ResultsLowerSpacer,
	PotentialGrowthTitle,
	ResultsContainer,
	ResultsContent,
	ButtonContainer,
	WageContainer,
	HourlyWageTitle,
	UpperWageContainer,
	LowerWageContainer,
	TotalDiv,
	ResultsTextContainer,
	PotentialSavingsContainer,
	Input,
} from './components'
import {
	WordingBreak,
	isBackspacingTheEndOfInput,
} from 'views/components/roi-calc/input-item/shared'
import {
	formatCurrency,
	formatCurrencyWithDecimals,
	roundHours,
	getNumbersFromKeyDownBackspaceWithEndingSymbol,
	stripIllegalChars,
} from 'views/components/roi-calc/shared'
import { laptop, mobile } from 'shared/media-queries'
import { countryCodes, currencySymbols } from 'shared/get-country'
import styled from 'styled-components'
import { Button } from 'views/components'
import { cyan, brandBlue } from 'shared/colors'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'
import { showRoiReportModal } from 'components/modal-manager'

// prettier-ignore
interface StateValues {
	subcontractors: number;
	hoursSpent: number;
	riskManagers: number;
}

// prettier-ignore
interface ResultsProps {
	values: StateValues;
	country: string;
}

// prettier-ignore
interface ResultItemProps {
	title: string;
	tooltipWording: React.ReactNode;
	tooltipHeader: React.ReactNode;
	resultWording: React.ReactNode;
}

const Link = ({ children, ...other }) => (
	<A
		color={brandBlue}
		target="_blank"
		onClick={e => e.stopPropagation()}
		{...other}
	>
		{children}
	</A>
)

const PGDiv = styled.div`
	display: flex;
	align-items: baseline;
`

// prettier-ignore
interface WageProps {
	wage: any;
	updateWage: (wage: any) => any;
	country: string;
}

const WageSection: React.FC<WageProps> = ({ wage, updateWage, country }) => {
	const [isEditing, toggleEdit] = useState(false)

	const currencySymbol = currencySymbols[country]

	return (
		<WageContainer>
			<HourlyWageTitle>Risk Manager Hourly Wage:</HourlyWageTitle>
			<Input
				onClick={() => {
					toggleEdit(true)
				}}
				value={
					isEditing
						? wage
						: `${currencySymbol}${formatCurrencyWithDecimals(wage)}/hr`
				}
				onChange={e => {
					const updatedWage = stripIllegalChars(e)
					updateWage(updatedWage)
				}}
				onKeyDown={e => {
					if (isBackspacingTheEndOfInput(e)) {
						e.preventDefault()
						const updatedWage = getNumbersFromKeyDownBackspaceWithEndingSymbol(
							e
						)
						updateWage(updatedWage)
					}
				}}
				onBlur={() => {
					toggleEdit(false)
				}}
				onKeyPress={event => {
					if (
						event.target instanceof HTMLInputElement &&
						event.key === 'Enter'
					) {
						event.target.blur()
					}
				}}
			/>
		</WageContainer>
	)
}

const ResultItem: React.FC<ResultItemProps> = ({
	title,
	tooltipWording,
	tooltipHeader,
	resultWording,
}) => {
	return (
		<div>
			<ResultsItemContainer>
				{title}
				<SmallWidthSpacer />
				<ResultsSpan>
					<QuestionMarkTooltip
						title={
							<div>
								<TooltipHeader>{tooltipHeader}</TooltipHeader>
								<SmallSpacer />
								<TooltipContent>{tooltipWording}</TooltipContent>
							</div>
						}
						wide
						fillColor="white"
					/>
				</ResultsSpan>
			</ResultsItemContainer>
			<SmallSpacer />
			<ResultContent>{resultWording}</ResultContent>
		</div>
	)
}

const ResultsSection: React.FC<ResultsProps> = ({ values }) => {
	const { hoursSpent, subcontractors, riskManagers } = values
	const [wage, updateWage] = useState(40)
	const country = countryCodes.us
	const hoursSavedPerSub = hoursSpent * 0.33
	const timeSavedPerYear = (hoursSpent - hoursSavedPerSub) * subcontractors
	// const overheadPerSub = hoursSpent * (wage * 8) - hoursSavedPerSub * (wage * 8)
	const overheadPerSub = hoursSpent * wage * 0.33 * riskManagers
	const overheadPerYear = overheadPerSub * subcontractors
	const potentialGrowth = overheadPerYear
	const showPotentialGrowthEasterEgg = overheadPerYear >= 999999999

	return (
		<RootContainer>
			<ResultsContainer>
				<ResultsTextContainer>
					<UpperWageContainer>
						<WageSection
							wage={wage}
							updateWage={updateWage}
							country={country}
						/>
					</UpperWageContainer>
					<ResultsContent>
						<TotalDiv>
							<PotentialSavingsContainer>
								{showPotentialGrowthEasterEgg
									? 'MANY'
									: roundHours(timeSavedPerYear).toLocaleString()}
							</PotentialSavingsContainer>
							<PotentialGrowthTitle>
								HRS/YR TIME SAVED{' '}
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>How was this calculated?</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												TradeTapp users report that TradeTapp cuts the time it
												takes to qualify subcontractors by 33%.
												<br />
												Improve your efficiency by only spending{' '}
												<b>
													{hoursSavedPerSub > 0.34
														? roundHours(hoursSavedPerSub)
														: '0.33'}{' '}
												</b>{' '}
												hours per qualification, saving you{' '}
												<b>
													{hoursSavedPerSub > 0.34
														? roundHours(timeSavedPerYear)
														: 0.66}
												</b>{' '}
												hours per year.
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</PotentialGrowthTitle>
						</TotalDiv>
						<VariableSpacer />
						<TotalDiv>
							<PotentialSavingsContainer>
								{showPotentialGrowthEasterEgg
									? 'A LOT'
									: formatCurrency(overheadPerYear)}
							</PotentialSavingsContainer>
							<PotentialGrowthTitle>
								IN POTENTIAL SAVINGS{' '}
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>How was this calculated?</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												According to{' '}
												<Link href="https://www.ziprecruiter.com/Salaries/Construction-Risk-Management-Salary">
													ZipRecruiter
												</Link>
												, the average construction risk manager’s salary is
												almost $80,000 - or about $40 per hour. <WordingBreak />
												With a faster workflow, you could save{' '}
												<b>{formatCurrency(overheadPerSub)}</b> per
												qualification and{' '}
												<b>{formatCurrency(overheadPerYear)}</b> per year in
												overhead.
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</PotentialGrowthTitle>
						</TotalDiv>
						<ResultsSpacer />
					</ResultsContent>
					<ResultsLowerSpacer />
				</ResultsTextContainer>

				<ButtonContainer>
					<LowerWageContainer>
						<WageSection
							wage={wage}
							updateWage={updateWage}
							country={country}
						/>
					</LowerWageContainer>
					<Button
						width="100%"
						label="See your ROI report"
						backgroundColor={cyan}
						onClick={() =>
							showRoiReportModal({
								...values,
								hoursSavedPerSub,
								hoursSpent,
								timeSavedPerYear,
								overheadPerSub,
								overheadPerYear,
								potentialGrowth,
							})
						}
						css={{
							'max-width': '375px',
							'max-height': '44px',
							[laptop]: {
								flex: '0 0 50%',
								'max-width': '50%',
								'margin-top': '25px',
							},
							[mobile]: { 'max-width': '100%' },
						}}
					/>
				</ButtonContainer>
			</ResultsContainer>
		</RootContainer>
	)
}

export default ResultsSection
